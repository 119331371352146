



































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import ShipOwner from '@/models/ShipOwner.model';
import {shipOwnerStoreActions} from '@/store/shipOwner.store';
import {UserRole} from '@/models/User.model';
import {LanguageInterface} from '@/components/shared/LanguageSelect.vue';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

const ShipOwnerStore = namespace('shipOwner');

@Component({
  components: {}
})
export default class VesselTableComponent extends Vue {

  @ShipOwnerStore.Action(shipOwnerStoreActions.UPDATE)
  public updateShipOwner!: (payload: {
    id: string, status: boolean, locale: string, email?: string[]
  }) => Promise<ShipOwner>

  @ShipOwnerStore.Action(shipOwnerStoreActions.DELETE)
  public deleteShipOwner!: (payload: { id: string }) => Promise<ShipOwner>

  @Prop({default: () => []})
  public shipOwners!: ShipOwner[];

  public availableLanguages: LanguageInterface[] = [{
    locale: 'de', flagKey: 'DE'
  }, {
    locale: 'en', flagKey: 'GB'
  }];

  @Prop({default: ''})
  public searchValue!: string;

  public tableHeaders = [{
    text: this.$t('SETTINGS.SHIPOWNER_VIEW.TABLE.NAME'),
    value: 'name',
    sortable: true
  }, {
    text: this.$t('SETTINGS.SHIPOWNER_VIEW.TABLE.EMAIL'),
    value: 'email',
    sortable: false,
    width: 600
  }, {
    text: this.$t('SETTINGS.SHIPOWNER_VIEW.TABLE.ADDRESS'),
    value: 'address',
    sortable: true
  }, {
    text: this.$t('SETTINGS.SHIPOWNER_VIEW.TABLE.COUNTRY'),
    value: 'country',
    sortable: true
  }, {
    text: this.$t('SETTINGS.SHIPOWNER_VIEW.TABLE.COLOR'),
    value: 'color',
    sortable: true
  }, {
    text: this.$t('SETTINGS.SHIPOWNER_VIEW.TABLE.LOCALE'),
    value: 'locale',
    sortable: false
  }, {
    text: this.$t('SETTINGS.SHIPOWNER_VIEW.TABLE.STATUS'),
    value: 'status',
    sortable: false
  }, {
    text: this.$t('SETTINGS.SHIPOWNER_VIEW.TABLE.OPTIONS'),
    value: 'options',
    sortable: false,
    align: 'right'
  }];

  public validateMail(shipOwner: ShipOwner) {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    shipOwner.email = shipOwner.email.filter(email => regexp.test(email));
    this.updateShipOwnerData(shipOwner);
  }

  public async updateShipOwnerStatus(shipOwner: ShipOwner) {
    await this.updateShipOwner({id: shipOwner.id!, locale: shipOwner.locale!, status: !shipOwner.status!});
  }

  public async deleteShipOwnerClicked(shipOwner: ShipOwner) {
    await this.deleteShipOwner({id: shipOwner.id!});
  }

  public getFlag(country: string) {
    return getUnicodeFlagIcon(country);
  }

  public async updateShipOwnerData(shipOwner: ShipOwner) {
    try {
      await this.updateShipOwner({
        id: shipOwner.id!, status: shipOwner.status!,
        email: shipOwner.email, locale: shipOwner.locale!
      });
      Vue.notifySuccessSimplified('SETTINGS.SHIPOWNER_VIEW.NOTIFICATIONS.SAVED_DATA');
    } catch (e) {
      Vue.notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }

  }

  public get viewOnly(): boolean {
    return this.$hasRole(UserRole.PLANER)
  }
}

