var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shipOwner-table mt-3"},[_c('v-card',{staticClass:"box-standard-shadow py-1"},[_c('v-data-table',{attrs:{"height":"500","items":_vm.shipOwners,"headers":_vm.tableHeaders,"search":_vm.searchValue,"items-per-page":10},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
var item = ref.item;
return [(item.street)?_c('div',[_vm._v(_vm._s(item.street)+", "+_vm._s(item.zipCode)+" "+_vm._s(item.city))]):_vm._e()]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.viewOnly},on:{"click":function($event){return _vm.updateShipOwnerStatus(item)}}},[(item.status)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-ban")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-check-circle")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.viewOnly},on:{"click":function($event){return _vm.deleteShipOwnerClicked(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-trash")])],1)],1)]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-combobox',{staticClass:"email-box my-3",attrs:{"clearable":"","hide-details":"","disabled":_vm.viewOnly,"multiple":"","small-chips":"","deletable-chips":""},on:{"change":function($event){return _vm.validateMail(item)}},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})]}},{key:"item.locale",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{staticClass:"language-select",attrs:{"disabled":_vm.viewOnly,"hide-details":"","items":_vm.availableLanguages,"item-value":"locale","outlined":"","dense":""},on:{"change":function($event){return _vm.updateShipOwnerData(item)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(_vm._s(_vm.getFlag(item.flagKey)))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(_vm._s(_vm.getFlag(item.flagKey)))]),_c('div',{staticClass:"ml-1 black--text"},[_vm._v(_vm._s(_vm.$t(item.flagKey)))])])]}}],null,true),model:{value:(item.locale),callback:function ($$v) {_vm.$set(item, "locale", $$v)},expression:"item.locale"}})],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('div',[_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("fas fa-check")])],1):_c('div',[_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("far fa-times-circle")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }